















































import { Component, Vue } from 'vue-property-decorator';
import { conductors } from './conductorData';
import { ConductorI } from '@/typings';

@Component({})
export default class Conductor extends Vue {
  conductors: ConductorI[] = conductors;
  isImageLoaded: boolean = false;

  smallMobileBreakPoint: MediaQueryList = window.matchMedia(
    '(max-width: 350px)'
  );
  isExtraSmallScreen: boolean = this.smallMobileBreakPoint.matches;

  mounted(): void {
    this.smallMobileBreakPoint.onchange = this.mediaQueryHandler;
  }

  get imageHeight(): number {
    return this.isExtraSmallScreen ? 369 : 438;
  }

  get imageWidth(): number {
    return this.isExtraSmallScreen ? 250 : 300;
  }

  mediaQueryHandler(): void {
    this.isExtraSmallScreen = this.smallMobileBreakPoint.matches;
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }
}
